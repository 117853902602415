@import "old/sass/theme/config";
@import "old/sass/bootstrap/variables";
@import "old/sass/bootstrap/bootstrap.scss";

.kt-subheader {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  margin: 10px 0
}

.kt-subheader .kt-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.kt-subheader .btn {
  display: inline-flex;
  height: 38px !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: .25rem;
  margin-bottom: .25rem
}

.kt-subheader .btn i {
  font-size: 1rem !important
}

.kt-subheader .btn.btn-icon {
  width: 32px !important
}

.kt-subheader .btn:not(.dropdown-toggle-split) {
  margin-left: .5rem
}

.kt-subheader .kt-subheader__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: .25rem 0
}

.kt-subheader .kt-subheader__main .btn:not(.dropdown-toggle-split) {
  margin-left: 0;
  margin-right: .5rem
}

.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle {
  display: none
}

.kt-subheader .kt-subheader__main .kt-subheader__title {
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #434349
}

.kt-subheader .kt-subheader__main .kt-subheader__title small {
  padding-left: .25rem;
  font-size: .9rem;
  color: #74788d
}

.kt-subheader .kt-subheader__main .kt-subheader__desc {
  padding: 0 .5rem 0 0;
  font-size: 1rem;
  font-weight: 500;
  color: #959cb6
}

.kt-subheader .kt-subheader__main .kt-subheader__separator {
  display: block;
  width: 12px;
  height: 4px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 1rem 0 0
}

.kt-subheader .kt-subheader__main .kt-subheader__separator.kt-subheader__separator--v {
  width: 1px;
  height: 22px
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs {
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home {
  padding: 0 .65rem 0 0;
  transition: all .3s
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home>i {
  font-size: 1rem;
  color: #c5cbe7
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home:hover>i {
  transition: all .3s;
  color: #5d78ff
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home.kt-subheader__breadcrumbs-home--active>i {
  color: #5d78ff
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link {
  padding: 0 .35rem 0 0;
  font-size: 1rem;
  font-weight: 500;
  color: #959cb6;
  transition: all .3s
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link.kt-subheader__breadcrumbs-link--active,
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover {
  color: #5d78ff
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover {
  transition: all .3s
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator {
  display: flex;
  justify-content: content;
  align-items: center;
  padding: 0 .35rem 0 0
}

.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: " ";
  background: #e7e8ef
}

.kt-subheader .kt-subheader__main .kt-subheader__group {
  display: flex;
  align-items: center
}

.kt-subheader .kt-subheader__toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0
}

.kt-subheader .kt-subheader__toolbar .btn:not(.dropdown-toggle-split) {
  margin-left: .25rem
}

.kt-subheader .kt-subheader__btn-primary {
  background: #f2f3f7;
  color: #959cb6;
  font-weight: 500
}

.kt-subheader .kt-subheader__btn-primary i {
  color: #959cb6
}

.kt-subheader .kt-subheader__btn-primary.active,
.kt-subheader .kt-subheader__btn-primary:active,
.kt-subheader .kt-subheader__btn-primary:focus,
.kt-subheader .kt-subheader__btn-primary:hover {
  color: #fff;
  background: #5d78ff
}

.kt-subheader .kt-subheader__btn-primary.active i,
.kt-subheader .kt-subheader__btn-primary:active i,
.kt-subheader .kt-subheader__btn-primary:focus i,
.kt-subheader .kt-subheader__btn-primary:hover i {
  color: #fff
}

.kt-subheader .kt-subheader__btn-primary.active g [fill],
.kt-subheader .kt-subheader__btn-primary:active g [fill],
.kt-subheader .kt-subheader__btn-primary:focus g [fill],
.kt-subheader .kt-subheader__btn-primary:hover g [fill] {
  transition: fill .3s ease;
  fill: #fff
}

.kt-subheader .kt-subheader__btn-primary.active:hover g [fill],
.kt-subheader .kt-subheader__btn-primary:active:hover g [fill],
.kt-subheader .kt-subheader__btn-primary:focus:hover g [fill],
.kt-subheader .kt-subheader__btn-primary:hover:hover g [fill] {
  transition: fill .3s ease
}

.kt-subheader .kt-subheader__btn-secondary {
  color: #959cb6;
  font-weight: 500
}

.kt-subheader .kt-subheader__btn-secondary i {
  color: #aab1df
}

.kt-subheader .kt-subheader__btn-secondary g [fill] {
  transition: fill .3s ease;
  fill: #aab1df
}

.kt-subheader .kt-subheader__btn-secondary:hover g [fill] {
  transition: fill .3s ease
}

.kt-subheader .kt-subheader__btn-secondary.active,
.kt-subheader .kt-subheader__btn-secondary:active,
.kt-subheader .kt-subheader__btn-secondary:focus,
.kt-subheader .kt-subheader__btn-secondary:hover {
  background: #f2f3f7;
  color: #5d78ff
}

.kt-subheader .kt-subheader__btn-secondary.active g [fill],
.kt-subheader .kt-subheader__btn-secondary:active g [fill],
.kt-subheader .kt-subheader__btn-secondary:focus g [fill],
.kt-subheader .kt-subheader__btn-secondary:hover g [fill] {
  transition: fill .3s ease;
  fill: #5d78ff
}

.kt-subheader .kt-subheader__btn-secondary.active:hover g [fill],
.kt-subheader .kt-subheader__btn-secondary:active:hover g [fill],
.kt-subheader .kt-subheader__btn-secondary:focus:hover g [fill],
.kt-subheader .kt-subheader__btn-secondary:hover:hover g [fill] {
  transition: fill .3s ease
}

.kt-subheader .kt-subheader__btn-daterange {
  background: #f2f3f7
}

.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-title {
  display: inline-block;
  color: #959cb6;
  font-weight: 500
}

.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-date {
  display: inline-block;
  color: #5d78ff;
  margin-right: .75rem;
  font-weight: 500
}

.kt-subheader .kt-subheader__btn-daterange i {
  color: #5d78ff
}

.kt-subheader .kt-subheader__btn-daterange.active,
.kt-subheader .kt-subheader__btn-daterange:active,
.kt-subheader .kt-subheader__btn-daterange:focus,
.kt-subheader .kt-subheader__btn-daterange:hover {
  background: #f2f3f7
}

.kt-subheader .kt-subheader__btn-daterange.active i,
.kt-subheader .kt-subheader__btn-daterange:active i,
.kt-subheader .kt-subheader__btn-daterange:focus i,
.kt-subheader .kt-subheader__btn-daterange:hover i {
  color: #5d78ff
}

.kt-subheader .kt-subheader__search {
  width: 200px;
  display: inline-block
}

.kt-subheader .kt-subheader__search .form-control {
  font-size: .9rem;
  height: 33px;
  background: #f2f3f7;
  border-color: #f2f3f7 !important
}

.kt-subheader .kt-subheader__search .kt-input-icon__icon i {
  font-size: 1.2rem;
  color: #74788d
}

.kt-subheader--transparent .kt-subheader {
  background-color: transparent
}

.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__separator {
  background: #dbdce7
}

.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home>i {
  color: #b7bee1
}

.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link {
  color: #959cb6
}

.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after {
  background: #dbdce7
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary {
  background: #e1e3ec;
  color: #959cb6
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary i {
  color: #959cb6
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary g [fill] {
  transition: fill .3s ease;
  fill: #959cb6
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover g [fill] {
  transition: fill .3s ease
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover {
  background: #5d78ff;
  color: #fff
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover i {
  color: #fff
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover g [fill] {
  transition: fill .3s ease;
  fill: #fff
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active:hover g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active:hover g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus:hover g [fill],
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover:hover g [fill] {
  transition: fill .3s ease
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary {
  color: #8c94b0
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary i {
  color: #9fa7db
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:hover {
  background: #eaecf2
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange {
  background: #e1e3ec
}

.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:hover {
  background: #d7dbe6
}

.kt-subheader--transparent .kt-subheader .kt-subheader__search .form-control {
  background: #eaecf2;
  border-color: #eaecf2 !important
}

@media (max-width:1024px) {
  .kt-subheader {
    margin: 2px 0
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    width: 20px;
    height: 22px;
    transition: all .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span {
    display: block;
    position: absolute;
    top: 10px;
    height: 2px;
    min-height: 2px;
    width: 100%;
    border-radius: 2px;
    transition: all .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    min-height: 2px;
    content: "";
    border-radius: 2px;
    transition: all .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:before {
    top: -6px
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:after {
    bottom: -6px
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left span:before {
    transition: all .4s ease;
    left: auto;
    right: 0;
    width: 50%
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left span:after {
    transition: all .4s ease;
    left: auto;
    right: 0;
    width: 75%
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left:hover span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left:hover span:before {
    width: 100%;
    transition: all .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left.kt-subheader__mobile-toggle--active span:before {
    transition: all .4s ease;
    left: 0;
    right: auto;
    width: 50%
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left.kt-subheader__mobile-toggle--active span:after {
    transition: all .4s ease;
    left: 0;
    right: auto;
    width: 75%
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right span:before {
    left: 0;
    right: auto;
    width: 50%;
    transition: width .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right span:after {
    left: 0;
    right: auto;
    width: 75%;
    transition: width .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right:hover span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right:hover span:before {
    width: 100%;
    transition: width .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right.kt-subheader__mobile-toggle--active span:before {
    left: auto;
    right: 0;
    width: 50%;
    transition: width .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right.kt-subheader__mobile-toggle--active span:after {
    left: auto;
    right: 0;
    width: 75%;
    transition: width .4s ease
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span:before {
    background: #74788d
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span:before,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span:after,
  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span:before {
    background: #5d78ff
  }

  .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle {
    display: inline-block;
    margin-right: 1rem
  }

  .kt-subheader .kt-subheader__main .kt-subheader__title {
    padding: .5rem 1.25rem .5rem 0
  }

  .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs,
  .kt-subheader .kt-subheader__toolbar {
    padding: .5rem 0
  }

  .kt-subheader--transparent .kt-subheader {
    background-color: transparent
  }
}

@media (max-width:768px) {
  .kt-subheader .kt-subheader__toolbar {
    padding-top: .5rem
  }
}

.kt-input-icon {
  position: relative;
  padding: 0;
  width: 100%
}

.kt-input-icon>.kt-input-icon__icon {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  width: 3.2rem
}

.kt-input-icon>.kt-input-icon__icon.kt-input-icon__icon--left {
  left: 0
}

.kt-input-icon>.kt-input-icon__icon.kt-input-icon__icon--right {
  right: 0
}

.kt-input-icon>.kt-input-icon__icon>span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}

.kt-input-icon>.kt-input-icon__icon>span i {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #74788d
}

.kt-input-icon>.kt-input-icon__icon [class*=" la-"],
.kt-input-icon>.kt-input-icon__icon [class^=la-] {
  font-size: 1.4em
}

.kt-input-icon>.kt-input-icon__icon [class*=" fa-"],
.kt-input-icon>.kt-input-icon__icon [class^=fa-] {
  font-size: 1.2em
}

.kt-input-icon>.kt-input-icon__icon [class*=" flaticon2-"],
.kt-input-icon>.kt-input-icon__icon [class*=" flaticon-"],
.kt-input-icon>.kt-input-icon__icon [class^=flaticon2-],
.kt-input-icon>.kt-input-icon__icon [class^=flaticon-] {
  font-size: 1.3em
}

.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon {
  width: 4.2rem
}

.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class*=" la-"],
.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class^=la-] {
  font-size: 1.6em
}

.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class*=" fa-"],
.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class^=fa-] {
  font-size: 1.3em
}

.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class*=" flaticon2-"],
.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class*=" flaticon-"],
.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class^=flaticon2-],
.kt-input-icon .form-control.form-control-lg+.kt-input-icon__icon [class^=flaticon-] {
  font-size: 1.4em
}

.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon {
  width: 2.7rem
}

.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class*=" la-"],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class^=la-] {
  font-size: 1.1em
}

.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class*=" fa-"],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class*=" flaticon2-"],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class*=" flaticon-"],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class^=fa-],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class^=flaticon2-],
.kt-input-icon .form-control.form-control-sm~.kt-input-icon__icon [class^=flaticon-] {
  font-size: 1em
}

.kt-input-icon.kt-input-icon--left .form-control {
  padding-left: 2.8rem
}

.kt-input-icon.kt-input-icon--left .form-control.form-control-lg {
  padding-left: 3.6rem
}

.kt-input-icon.kt-input-icon--left .form-control.form-control-sm {
  padding-left: 2.5rem
}

.kt-input-icon.kt-input-icon--right .form-control {
  padding-right: 2.8rem
}

.kt-input-icon.kt-input-icon--right .form-control.form-control-lg {
  padding-right: 3.6rem
}

.kt-input-icon.kt-input-icon--right .form-control.form-control-sm {
  padding-right: 2.5rem
}

.kt-checkbox,
.kt-input-icon.kt-input-icon--fixed,
.kt-input-icon.kt-input-icon--fixed-large,
.kt-input-icon.kt-input-icon--fixed-small {
  display: inline-block
}

.kt-margin-b-10 {
  margin-bottom: 10px !important;
}

.kt-margin-l-20 {
  margin-left: 20px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  color: #3F4254;
  background-color: #f0f0f0;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border: 1px solid #f0f0f0;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  max-width: 1920px;
}

.kt-list-timeline {
  .kt-list-timeline__group {
    margin: 0 0 2rem 0;
  }

  .kt-list-timeline__heading {
    margin: 0 0 1.25rem 0;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .kt-list-timeline__items {
    position: relative;
    padding: 0;
    margin: 0;

    &:before {
      background-color: kt-base-color(grey, 2);
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 3px;
    }

    .kt-list-timeline__item {
      position: relative;
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 0.3rem 0;
      margin: 0.5rem 0;

      &:first-child,
      &:last-child {
        &:before {
          background-color: white;
          position: absolute;
          display: block;
          content: '';
          width: 1px;
          height: 50%;
          top: 0;
          bottom: 0;
          left: 3px;
        }
      }

      &:last-child {
        &:before {
          top: auto;
          bottom: 0;
        }
      }

      .kt-list-timeline__badge {
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        width: 20px;

        &:before {
          background-color: kt-base-color(grey, 2);
          position: absolute;
          display: block;
          content: '';
          width: 7px;
          height: 7px;
          left: 0;
          top: 50%;
          margin-top: -(7px / 2);
          border-radius: 100%;
        }

        @each $name,
        $color in $kt-state-colors {
          &.kt-list-timeline__badge--#{$name} {
            &:before {
              background-color: kt-get($color, base);
            }
          }
        }

      }

      .kt-list-timeline__icon {
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        width: 30px;
        padding: 0 5px 0 0;
        line-height: 0;
        color: kt-base-color(label, 2);
      }


      @include kt-icons-size((fontawesome: 1.2rem, lineawesome: 1.6rem, flaticon: 1.6rem));


      .kt-list-timeline__text {
        display: table-cell;
        text-align: left;
        vertical-align: middle;
        width: 100%;
        padding: 0 0 0 5px;
        font-size: 1rem;
        color: kt-base-color(label, 3);

        &:hover {
          text-decoration: none;
        }
      }

      .kt-list-timeline__time {
        display: table-cell;
        text-align: right;
        vertical-align: middle;
        width: 80px;
        padding: 0 0 0 5px;
        font-size: 0.9rem;
        color: kt-base-color(label, 2);
      }

      &:first-child {
        padding-top: 0;
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &:first-child,
      &:last-child {
        &:before {
          background-color: #fff;
        }
      }

      &.kt-list-timeline__item--read {

        .kt-list-timeline__badge,
        .kt-list-timeline__icon,
        .kt-list-timeline__text,
        .kt-list-timeline__time {
          opacity: 0.5;
        }
      }
    }
  }
}