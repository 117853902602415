//
// Base
//

// Body
body {
	background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}

	// Wrapper
	.wrapper {
		// Fixed Header and Minimized Header Modes
		.header-fixed & {
			padding-top: get($header-config, desktop, height) - get($header-config, desktop, height-bottom);
		}

		.header-fixed.header-bottom-enabled & {
			padding-top: get($header-config, desktop, height);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed Header Mode
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, height);
		}
	}
}
