@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');

#root {
  height: 100%;
}

.react-bootstrap-table table.table {
  table-layout: auto;
}

.react-bootstrap-table tbody {
  font-weight: 400;
}

.react-bootstrap-table thead th {
  font-weight: 600;
}

.react-bootstrap-table td {
  cursor: pointer;
}

.cursor-auto .react-bootstrap-table td {
  cursor: auto;
}

.react-bootstrap-table {
  .bg-danger {
    background-color: #f7d4d7 !important;
  }

  .bg-info {
    background-color: #dcebfe !important;
  }
}

.react-bootstrap-table-pagination a[role=menuitem] {
  padding: 0 1.2rem;
}

.react-bootstrap-table .table.table-bordered {
  margin-bottom: 0;
}

div.DateRangePickerInput__withBorder {
  border-radius: 4px;
}

div.DateInput {
  width: 100px;
  border-radius: 4px;
}

input.DateInput_input {
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;

  padding: 6px 11px 5px;
}

#startDate {
  border-bottom-right-radius: 0;
}

#endDate {
  border-bottom-left-radius: 0;
}

input.DateInput_input__focused {
  border-bottom: 2px solid #5d78ff;
}

.pagination .page-item.active .page-link {
  z-index: 0
}

.form-group {
  margin-bottom: 1rem;
}

.pointer {
  cursor: pointer;
}

$border-color: #ccc;
$border-type: solid;

.agg-tree {
  color: #000;

  .node {
    padding-left: 10px;
  }

  .node.nopadding {
    padding-left: 0;
  }

  .node.nopadding .kt-portlet {
    margin: 0px !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .node .kt-portlet__body {
    padding: 10px;
  }

  .node-total strong {
    /*
    display: block;
    margin-bottom: 0.5rem
    */
  }

  >.node {
    padding-left: 0;
  }

  .node-total {
    display: inherit;
  }

  .node-intervals {
    display: inherit;
  }

  .node-intervals-header {
    margin: 0;
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #434349;
  }

  .node-subinterval {
    position: relative;
  }

  .node-interval:last-child {
    border-bottom: 0;
  }

  .node-interval-header {
    padding: 0.5rem;
    border-bottom: 1px solid #f7f8fa;
    transition: background-color 0.3s ease;
  }

  .node-interval-header.expanded {
    border-bottom: 1px $border-type $border-color;
  }

  .node-interval-header:hover {
    transition: background-color 0.3s ease;
    background-color: #f7f8fa;
  }

  .node-interval-switch {
    padding: 0.5rem;
    border-bottom: 1px solid #f7f8fa;
    transition: background-color 0.3s ease;
    position: relative
  }

  .node-interval-switch:hover {
    transition: background-color 0.3s ease;
    background-color: #f7f8fa;
  }

  .node-interval-switch:last-child {
    border-bottom: 0;
  }

  .node-interval-switch-header {
    i {
      position: relative;
      top: 2px;
    }
  }
}

.react-bs-table-sizePerPage-dropdown>button.btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.special-table .react-bootstrap-table .row-expansion-style {
  padding: 0;
}

.react-bootstrap-table {
  overflow: auto;
}

.table.extra {
  table-layout: initial;
  margin-left: 30px;
  margin-bottom: 0;
}

.table.extra td,
.table.extra th {
  padding: .3rem;
  vertical-align: middle;
}

.row-changes {
  background-color: #f8d7da !important;
  color: #721c24;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
  vertical-align: middle;
}

.datetime-range-picker {
  .rdt {
    display: inline-block;
    position: relative
  }

  .rdt:last-child:after {
    display: block;
    content: ' ';
    background-image: url(/assets/images/arrow.svg);
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 8px;
    left: -9px;
  }

  .rdt>input {
    width: 160px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    font-weight: 200;
    color: #484848;
    padding: 5px 11px;
    border: 0 !important;
    height: 36px;
  }
}

.datetime-range-picker {
  background-color: #F3F6F9;
  color: #3F4254;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid transparent;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;

  input {
    background-color: #F3F6F9;
    color: #3F4254;
  }

  input:focus {
    background-color: #EBEDF3;
    color: #3F4254;
  }

}

.datetime-range-picker:hover {
  border: 1px solid hsl(0, 0%, 70%);
}

.datetime-range-picker:focus-within {
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff !important;
}

.top-control {
  display: inline-block;
  width: auto
}

.react-bootstrap-table {
  display: block;
  width: 100%;
  //  overflow-x: auto;
  // -webkit-overflow-scrolling: touch;
}

.nowrap {
  white-space: nowrap;
}

.mysuccess {
  background: #1dc9b7;
  border-bottom: 1px solid #ccc;
  color: #ffffff;
}

.myerror {
  background: #fd27eb;
  border-bottom: 1px solid #ccc;
  color: #ffffff;
}

.w100 {
  width: 100%;
}

div.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.raw-aggregations-expander {
  padding: 10px;
}

.counter-section {
  font-size: 14px;
  padding-bottom: 5px;
  display: inline-block;
}

.react-json-view {
  width: 100%;
  overflow: auto;
  max-height: 300px;
}

.node-total-header {
  font-size: 14px;
}

.pr {
  position: relative;
}

.pr.pr-loading {
  opacity: .5;
}

.pa-loader {
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.form-control[readonly] {
  border: 1px solid rgba(226, 229, 236, .3);
}

.txt-monospace {
  font-family: 'Roboto Mono', monospace;
}

.basic-single {
  .select__control {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;

    .select__value-container {
      padding-left: 1rem;
      padding-right: 1rem;

      .select__multi-value {
        background-color: rgba(0, 82, 204, 0.1);

        .select__multi-value__label {
          color: rgb(0, 82, 204);
        }

        .select__multi-value__remove {
          color: rgb(0, 82, 204);
        }

        .select__multi-value__remove:hover {
          background-color: rgb(0, 82, 204);
          color: white;
        }
      }

      .select__single-value,
      .select__placeholder {
        margin: 0;
      }
    }
  }

  .select__control--is-focused {
    background-color: #EBEDF3;
    border-color: #EBEDF3;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }
}

.form-control.form-control-solid:hover {
  border: 1px solid hsl(0, 0%, 70%);
}

.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff !important;
}

.jackpot-edit {
  .legend {
    width: auto;
    padding: 0 1rem;
  }

  .checkbox-inline {
    .checkbox {
      margin-bottom: 0;
    }

    label.checkbox.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  .card-body {
    padding-top: 1rem !important;
  }

  .select-row {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    overflow: visible;

    .item {
      flex: 1 1 0px;
      min-width: 20%;
      max-width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 3px;
    }

    .item.coefficient {
      padding: 3px 3px;
    }
  }

  .select-row.mr {
    margin-right: 15px;

    .form-check-input {
      position: relative;
      margin: 0;
    }
  }

  .kn-list {
    overflow-y: scroll !important;
    overflow-x: auto !important;
  }

  .kn-search {
    background-color: #F3F6F9;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }

  .kn-column {
    border-right: 0;
  }

}

.bg-progress-bar {
  background-color: #0066cc;
}

.modal-edit-counter {
  .form-group {
    margin-bottom: 1rem;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

table.aggregations {
  margin-top: 10px;

  th,
  td {
    padding: 0 10px;
  }

  td {
    text-align: right;
  }

  thead {
    th {
      text-align: center;
    }
  }

  th,
  td {
    border: 1px solid #ccc;
  }
}

.kt-margin-l-20 {
  display: flex;
}

.kt-subheader {
  .kt-subheader__search {
    input.form-control {
      height: 38px;
      background-color: #F3F6F9;
      border: 1px solid transparent;
      color: #3F4254;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    }

    input.form-control:hover {
      border: 1px solid hsl(0, 0%, 70%) !important;
      border-color: hsl(0, 0%, 70%) !important;
    }

    input.form-control:focus {
      background-color: #EBEDF3;
      color: #3F4254;
      transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }


  }
}

.bg-warning-2 {
  background-color: #ff7407;
}

.text-white {
  .text-muted {
    color: #ebebff !important;
  }
}

.expand-cell-header {
  cursor: pointer;
}

.selection-cell-header,
.selection-cell {
  vertical-align: middle;
  text-align: center;
}

.table-actions {
  display: inline-block;
  margin-right: 0.5rem;

  .dropdown {
    display: inline-block;
    margin-right: 0.5rem;
  }

  button {
    display: inline-block;
  }
}

.form-group {
  label.form-label {
    font-weight: 500;
  }
}

.ace-editor-borders {
  border-radius: 0.42rem;
  overflow: hidden;
  border: 1px solid transparent;

  .ace_gutter {
    background: #F3F6F9;
  }
}

.ace-editor-borders:hover {
  border: 1px solid #b3b3b3;
}

.ace-editor-borders:focus-within {
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff !important;

  .ace_gutter {
    background: #EBEDF3;
  }
}

@-moz-document url-prefix() {
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']:focus,
  input[type='number']:hover {
    -moz-appearance: initial;
    padding-right: 0;
  }
}

.topbar{
  .username{
    text-overflow: ellipsis;
    max-width: 140px;
    overflow: hidden;
    width: min-content;
  }
}


select.form-control {
  display: block;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  appearance: none;
}

@media (max-width: 720px){
  #kt_subheader_search {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  
    .kt-margin-l-20 {
      margin-left: 0px !important;
      margin-top: 10px;
      .gf-utrp-buttons{
        button{
          text-align: left;
          .gf-tpbl-container{
            span{
              span{
                &:last-of-type{
                  display: inline-block;
                }
              }
            }
          }
        }
        >.trp-btn.navbar-button--tight{
          display: none;
        }
      }
    }
  }
}

// Tablet & Mobile Modes
@media (max-width: 1199px){
  .main-logo{
    img{
      width: 110px;
    }
  }
	.header-tabs {
		flex-wrap: nowrap;
		overflow: auto;

		.nav-item {
			flex-wrap: nowrap;
			white-space: nowrap;

			.nav-link {
				flex-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}

@media (min-width: 1299px){
  .topbar{
    .username{
      max-width: 240px;
    }
  }
}

@media (max-width: 1350px){
  .main-logo{
    img{
      width: 110px;
    }
  }
  .header-tabs {
		flex-wrap: nowrap;
		overflow: auto;

		.nav-item {
			flex-wrap: nowrap;
			white-space: nowrap;

			.nav-link {
				flex-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}

@media (min-width: 1399px){
  .topbar{
    .username{
      max-width: 300px;
    }
  }
}