 //
// Header Menu
//

// Desktop Mode
@include menu-hor-build-layout(get($header-menu-config, desktop));
@include menu-hor-build-theme(get($header-menu-config, desktop), default);

// Tablet & Mobile Mode
@include menu-ver-build-layout(get($header-menu-config, tablet-and-mobile));
@include menu-ver-build-theme(get($header-menu-config, tablet-and-mobile), default);

// Header Navs Mobile Offcanvas
@include offcanvas-build(header-navs, tablet-and-mobile, get($header-menu-config, offcanvas-mobile));

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Header Tabs
	.header-tabs {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;

		// Fixed Header and Minimized Header Modes
		.header-fixed & {
			//align-items: center !important;
			//align-self: center !important;
		}

		.nav-item {
			padding: 0;
			margin-right: 0.25rem;
			position: relative;

			.nav-link {
				margin: 0;
				padding: 0.85rem 1.25rem;
				font-weight: 500;
				opacity: 0.8;
				color: $white;
				transition: $transition-link;
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;

				// Fixed Header and Minimized Header Modes
				.header-fixed[data-header-scroll="on"] & {
					//border-radius: $border-radius;
				}

				&:focus,
				&:hover {
					opacity: 1;
					color: $white;
					transition: $transition-link;
				}

				&.active {
					background-color: $page-bg;
					opacity: 1;
					color: $dark;
					transition: $transition-link;

					.header-bottom-enabled & {
						background-color: $white;
					}
				}
			}
		}
	}

	// Header Navs
	.header-navs {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		flex-grow: 1;

		.tab-content {
			display: flex;
			align-items: stretch;
			flex-grow: 1;
		}

		.tab-pane {
			display: none;
			align-items: stretch;
			flex-grow: 1;

			&.active {
				display: flex;
			}
		}
	}

	// Header Menu
	.header-menu {
		display: flex;
		align-items: stretch;

		// Header Nav
		.menu-nav {
			display: flex;
			align-items: stretch;

			> .menu-item {
				&:first-child {
					padding-left: 0;
				}

				> .menu-link {
					@include border-radius($border-radius);
					padding: 0.85rem 1.35rem;

					.menu-text {
						color: $dark-50;
						font-weight: 500;
						font-size: 1.05rem;
					}

					.menu-arrow {
						color: $text-muted;
					}

					.menu-icon {
						i {
							color: $text-muted;
						}

						.svg-icon {
							@include svg-icon-color($text-muted);
						}
					}
				}

				// Hover States
				&:hover:not(.menu-item-here):not(.menu-item-active),
				&.menu-item-hover:not(.menu-item-here):not(.menu-item-active) {
					.menu-text {
						color: $primary;
					}

					.menu-arrow {
						color: rgba($primary, 0.8);
					}

					.menu-icon {
						i {
							color: rgba($primary, 0.8);
						}

						.svg-icon {
							@include svg-icon-color(rgba($primary, 0.8));
						}
					}
				}

				// Active States
				&.menu-item-here,
				&.menu-item-active {
					> .menu-link {
						background-color: $gray-100;

						.menu-text {
							color: $primary;
						}

						.menu-arrow {
							color: rgba($primary, 0.8);
						}

						.menu-icon {
							i {
								color: rgba($primary, 0.8);
							}

							.svg-icon {
								@include svg-icon-color(rgba($primary, 0.8));
							}
						}
					}
				}
			}
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.header-tabs {
		flex-wrap: nowrap;
		overflow: auto;
		position-x: relative;

		.nav-item {
			flex-wrap: nowrap;
			white-space: nowrap;

			.nav-link {
				flex-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}
